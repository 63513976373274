import React, { useEffect, useState } from 'react';
import StatusElement from '../components/status-element';
import ChangeStatus from '../components/change-status';
import Badge from '../components/badge';

const Page = () => {
  const [loadingResults, setLoadingResults] = useState(true);
  const [results, setResults] = useState(null);
  const MINUTE_MS = 60000;

  function loadResults() {
    setLoadingResults(true);
    try {
      fetch(`/api/coffee-status`).then(response => response.json())
        .then(data => {
          // Race cond hack
          if (results && results['req_time'] < data.data['req_time']) {
            setResults(data.data);
          } else if (!results) {
            setResults(data.data);
          }
          setLoadingResults(false);
        });
    } catch (error) {
      console.log(error);
      setLoadingResults(false);
    }
  }

  useEffect(() => {
    const interval = setInterval(() => {
      loadResults();
    }, MINUTE_MS / 2);

    return () => clearInterval(interval);
  }, [])


  const handleClick = async (item, choice) => {
    setLoadingResults(true);
    try {
      const response = await fetch(`/api/coffee-vote?key=${item}&status=${choice}`);
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      await response.json();
      loadResults();
    } catch (error) {
      setLoadingResults(false);
    }
  };



  useEffect(() => {
    console.log('loading...')
    loadResults();
  }, []);

  return (
    <>
      {/* Status */}
      <section className="border border-gray-200 rounded-lg px-4 py-6 sm:p-4 max-w-6xl mx-auto">
        {/* <h3 class="ml-4">K17 CSE Coffee Machine Status</h3> */}
        {results ? <>

          <div className="flex flex-wrap">
            <StatusElement title="Machine Status" status={results['machine'] ? 'Operational' : 'Offline'} fullWidth={true} />
            <StatusElement title="Beans" status={results['beans'] ? 'Okay' : 'None'} fullWidth={false} />
            <StatusElement title="Milk" status={results['milk'] ? 'Okay' : 'None'} fullWidth={false} />
            <StatusElement title="Hot Chocolate" status={results['hot_chocolate'] ? 'Okay' : 'None'} fullWidth={false} />
          </div>
          <div className="flex flex-wrap">

            <section className="ml-5">
              <Badge text={"Last updated: " + results['last_modified']} />
              {
                loadingResults ? <Badge text="Loading new data..." /> : <></>
              }
            </section>
          </div>
        </> :
          <>
            <p>Loading...</p>
          </>}
      </section>

      {/* Change Status */}
      <section className="border border-gray-200 rounded-lg px-4 py-6 sm:p-4 max-w-6xl mx-auto mt-12">
        {/* <h4>Update Machine Status</h4> */}
        <ChangeStatus item="machine" callback={handleClick} />
        <ChangeStatus item="beans" callback={handleClick} />
        <ChangeStatus item="milk" callback={handleClick} />
        <ChangeStatus item="hot_chocolate" callback={handleClick} />

      </section>
    </>
  );
};

export default Page;
